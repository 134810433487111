import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import HubspotForm from "react-hubspot-form";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import "./styles.scss";
import {
	SHARPSPRING_DOMAIN,
	SHARPSPRING_ACCOUNT,
	SHARPSPRING_TARGET_ID,
	SHARPSPRING_FORM_JS_SCRIPT,
} from "../../utils/constants";

function BasicPage({ data: { basicPage } }) {
	const {
		title,
		subtitle,
		body,
		seoDescription: { seoDescription },
		sharpSpringFormId,
		hubSpotFormId,
		headerImage,
	} = basicPage;

	return (
		<>
			{/* Add SharpSpring embed form to basic landing page. 
			The SharpSpringForm ID must be set in contentful to display the intended form at the bottom of the page */}
			{sharpSpringFormId && (
				<Helmet>
					<script type="text/javascript">
						{`
						var ss_form = {'account': '${SHARPSPRING_ACCOUNT}', 'formID': '${sharpSpringFormId}'};
						ss_form.width = '100%';
						ss_form.domain = '${SHARPSPRING_DOMAIN}';
						ss_form.target_id = '${SHARPSPRING_TARGET_ID}';
						ss_form.polling = true;
						`}
					</script>
					<script
						type="text/javascript"
						src={`${SHARPSPRING_FORM_JS_SCRIPT}`}
					></script>
				</Helmet>
			)}
			<Layout>
				<SearchEngineOptimization title={title} description={seoDescription} />
				<div className="basic-page">
					<div
						className="basic-page__background"
						style={{
							backgroundImage: `linear-gradient(rgba(40, 40, 40, .3), rgba(40, 40, 40, .7)), url(${headerImage.file.url})`,
						}}
					>
						<div className="container">
							<div className="basic-page__hero">
								<div>
									<h1 className="basic-page__title">{title}</h1>
								</div>
							</div>
						</div>
					</div>
					<div className="section">
						<div className="container container--is-narrow">
							<div className="basic-page__content">
								{subtitle && (
									<h2 className="basic-page__subtitle">{subtitle}</h2>
								)}
								{body && (
									<div
										className="basic-page__body markdown"
										dangerouslySetInnerHTML={{
											__html: body.childMarkdownRemark.html,
										}}
									/>
								)}
								{hubSpotFormId && (
									<HubspotForm
										portalId="544161"
										formId={`${hubSpotFormId}`}
										region="na1"
										css=""
										cssClass="email-sign-up-footer"
										submitButtonClass="btn btn--sm btn--black-green"
									/>
								)}
								<div id="SharpSpringForm"></div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
}

export const query = graphql`
	query ($slug: String!) {
		basicPage: contentfulBasicPage(slug: { eq: $slug }) {
			title
			subtitle
			body {
				childMarkdownRemark {
					html
				}
			}
			headerImage {
				file {
					url
				}
			}
			seoDescription {
				seoDescription
			}
			sharpSpringFormId
			hubSpotFormId
		}
	}
`;

export default BasicPage;
